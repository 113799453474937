import axios from 'axios';
import { AUTH_CONFIG } from './auth/auth0-variables';

//Looking for Rejections group and taking the client id
export function parseClient(groups) {
  for (let g in groups) {
    var res = groups[g].match(/Rejections/);
    if (res) {
      res = res.input.split(' ');
      return res[0];
    }
  }
}

export function checkUserForUpdateAccess(groups) {
 // console.log(groups)
  //for (let g in groups) {
    //var res = groups[g].match(/SkyBusiness/);
    //if (res) {
      //res = res.input.split(' ');
      //return true;
    //}
    //}
    return true
}

//retrieve ALL stores from storemaster table
export function makeStoreData(accessToken) {
  return axios.get(AUTH_CONFIG.baseUrl + '/stores',{
    headers : {
      Authorization : "Bearer " + accessToken
    },
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log(error);
  });
}

export function getAccountData(accessToken) {
  return axios.get(AUTH_CONFIG.baseUrl + '/accounts',{
    headers : {
      Authorization : "Bearer " + accessToken
    },
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log(error);
  });
}

//retrieve ALL stores from storemaster table
export function getStoreMetrics(accessToken, l_tpid, l_store_num) {
  return axios.get(AUTH_CONFIG.baseUrl + '/store_metrics', {
    headers: {
      Authorization: "Bearer " + accessToken
    },
    params: {
      tpid: l_tpid,
      store_num: l_store_num
    }
  }).then((response) => {
    console.log(response);
    return response.data;
  }).catch((error) => {
    console.log(error);
  });
}

//retrieves all new store that were loaded within past 2 weeks
export function makeNewStoreData(accessToken) {
  return axios.get(AUTH_CONFIG.baseUrl + '/new_stores', {
    headers: {
      Authorization: "Bearer " + accessToken
    },
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log(error);
  });
}

export function getStores() {
  return axios.get(AUTH_CONFIG.baseUrl + '/tpids',{
    responseType: 'text',
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log(error);
  });
}


export function assignAccess(client_id) {
  if (client_id === 'SKY') return true;
  else return false;
}

export function loadClients() {

  axios.get(AUTH_CONFIG.baseUrl + '/test')
    .then((response) => {

    })
    .catch((error) => {
      console.log(error);
    })

  return "Success?"

}

export function getClients() {
  //var clients = [];
  return axios.get(AUTH_CONFIG.baseUrl + '/clients').then((response) => {
    //var data = response.data;
    //return data;
    //for (var i = 0; i < data.length - 3; i += 3) {
      //clients.push(data.slice(i, i+3))}
  }).catch((error) => {
    console.log(error);
  })

}

export function getRates(client_id_param) {
  var rates = [];
  var dataList = [];

  return axios.get(AUTH_CONFIG.baseUrl + '/rates',{
    responseType: 'text',
    params: {
      client_id : client_id_param
    }
  }).then((response) => {
    dataList = response.data.split(" ").join().split(",");
    for(var i = 0;i <= dataList.length - 4; i += 5){

      rates[dataList[i]] = dataList[i+1]+','+dataList[i+2]+','+dataList[i+3]+','+dataList[i+4];
    }

    return rates;
  }).catch((error) => {
    console.log(error);
  });
}

export function getRates_Test(client_id_param) {
  //var rates = [];
  //var dataList = [];
  return fetch(AUTH_CONFIG.baseUrl + '/rates',{
    params:{
      client_id : client_id_param
    }
  }).then(response => {
    response.json();
  }).then(data => {

  });
}



export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.lenght < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
