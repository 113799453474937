import {
  parseClient,
  checkUserForUpdateAccess,
  formatDate,
  assignAccess,
  makeStoreData,
  makeNewStoreData
} from "./utils.js";
import Nav from "./nav.js";
import React, { useEffect, useState, useContext } from "react";
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import axios from "axios";
import "../css/store_master.css";
import Table from "./table.js";
import { AUTH_CONFIG } from "./auth/auth0-variables";
import FadeLoader from "react-spinners/FadeLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiContext } from "../contexts/ApiContext.js";

function StoreMaster() {

  const { name, user, isAuthenticated, logout } = useAuth0();
  const { getTableFilters, getStoreData, getStoreMetrics, makeStoreData, makeNewStoreData, submitData } = useContext(ApiContext);

  const [oData, setOData] = useState([])
  const [cData, setCData] = useState([])
  const [uData, setUData] = useState([])
  const [dc_flag, setDc_flag] = useState("")
  const [store_type, setStore_type] = useState("")
  const [tableFilters, setTableFilters] = useState();
  const [acc_select, setAccSelect] = useState("")
  const [show, setShow] = useState(false)
  const [modalRow, setModalRow] = useState()
  const [loaderAnimation, setLoaderAnimation] = useState("hidden")
  const [newStore_allStore, setNewStore_allstore] = useState('New Stores')

  const [account, setAccount] = useState(null)
  const [dcFlag, setDcFlag] = useState(null)
  const [storeType, setStoreType] = useState(null)
  const [storeList, setStoreList] = useState([])
  const [newStoreData, setNewStoreData] = useState([])
  const [modalState, setModalState] = useState([])


  let groups = "http://skypad_user/groups";
  let username = user.name
  let client_id = parseClient(user[groups])
  let profile_client = parseClient(user[groups])
  let updateAccess = checkUserForUpdateAccess(user[groups])

  useEffect(() => {
    // if (localStorage.getItem('access_token') === null) {
    //   auth.handleAuthentication();
    // } 

    if (parseClient(user[groups]) === "SKY") {
      getTableFilters().then(values => {
        setTableFilters(values)
      });
    }
  }, [])

  useEffect(() => {
    if (account) {
      setLoaderAnimation('');
      setUData([])

      if (newStoreData.length !== 0) {

        getStoreData({ account: account.value.replace("'", "\\'"), dcFlag: dcFlag === null ? 'all' : dcFlag.value, storeType: storeType === null ? 'all' : storeType.value, newStore: true }).then(values => {
          // setStoreList(values);
          setOData(values);
          setCData(values);
          setLoaderAnimation('hidden');
        });
      }

      else {
        getStoreData({ account: account.value.replace("'", "\\'"), dcFlag: dcFlag === null ? 'all' : dcFlag.value, storeType: storeType === null ? 'all' : storeType.value, newStore: false }).then(values => {
          // setStoreList(values);
          setOData(values);
          setCData(values);
          setLoaderAnimation('hidden');
        });
      }
    }
    else if (dcFlag || storeType) {
      if (dcFlag) {
        setDcFlag(null)
      }
      else if (storeType) {
        setStoreType(null)
      }
      alert("Please select an account first!")

    }
  }, [account, dcFlag, storeType])


  const handleChange = (e) => {
    if (e.value === '') {
      var filtered;
      var key = e.id;
      var originalDataTable = oData;
      setState({ [key]: e.value }, () => {

        if (!(acc_select === '' && dc_flag === '' &&
          store_type === '')) {
          filtered = originalDataTable.filter((item) => {
            return (acc_select.includes(item['Account']) ||
              dc_flag.includes(item['DC_FLAG']) ||
              store_type.includes(item['STORE_TYPE']));
          });

          setState({ cData: filtered }, () => {
            //this.updateExportData();
            parseItemData();
          });
        } else {
          setState({ cData: oData }, () => {
            //this.updateExportData();
            parseItemData();
          });
        }
      });
    } else {
      var filtered;
      var key = e.id;
      var value = e.value;
      getStoreData().then(x => {
        console.log(x)
      })
    }
  }

  //Load all updated stores back to vertica
  const handleSubmit = (e) => {
    e.preventDefault();
    let count, l;
    count = 0;
    l = uData.length;

    if (l > 0) {
      setLoaderAnimation('');
      uData.forEach(key => {
        if (uData[key] !== "") {

          console.log(key);
          console.log(uData[key]);

          submitData({
            row: key,
            modified_date: formatDate(Date.now()),
            modified_by: user.nickname
          })
            .then(() => {
              count++;
              if (count === l) {
                setLoaderAnimation('hidden');
                setUData([])
                console.log(`${username} edited row ${key}`)
                alert("All items updated!");
                window.location.reload();

              }
            })
            .catch(error => {
              console.log(error);
            });
          e.preventDefault();
        }
      });
    } else {
      alert("Nothing to update!");
    }
  }

  const generateStoreData = () => {

    setLoaderAnimation('');
    setUData([])
    setDcFlag(null)
    setStoreType(null)
    setAccount(null)
    setNewStoreData([])
    setCData([]);
    setNewStore_allstore('New Stores');
    alert('Now showing all stores!');
    setLoaderAnimation('hidden');
    // makeStoreData().then(values => {
    //   // if (oData !== values) {
    //   //   setOData(values);
    //   // }
    //   setNewStoreData([])
    //   setCData(values);


    //   setNewStore_allstore('New Stores');
    //   alert('Now showing all stores!');
    //   setLoaderAnimation('hidden');
    // });
  }

  const generateNewStoreData = () => {

    setLoaderAnimation('');
    setUData([])
    setDcFlag(null)
    setStoreType(null)
    setAccount(null)
    makeNewStoreData().then(values => {

      setNewStoreData(values)
      setCData(values);

      setNewStore_allstore('All Stores');
      alert('Now showing new stores!');
      setLoaderAnimation('hidden');
    });
  }

  //Reloading 3 filter lists on the top
  const parseItemData = () => {
    // Here we are parsing the data and looking only at distinct values for our filter dropdowns
    let items = cData;
    let listAccount = [], lookupAccount = {};
    let listDCflag = [], lookupDCFlag = {};
    let listStoreType = [], lookupStoreType = {};

    for (let item, i = 0; item = items[i++];) {

      if (!(item['Account'] in lookupAccount)) {
        lookupAccount[item['Account']] = 1;
        listAccount.push({ id: 'acc_select', value: item['Account'], label: item['Account'] });
      }

      if (!(item['DC_FLAG'] in lookupDCFlag)) {
        lookupDCFlag[item['DC_FLAG']] = 1;
        listDCflag.push({
          id: 'dc_flag',
          value: item['DC_FLAG'] === "None" ? null : item['DC_FLAG'],
          label: (item['DC_FLAG'] === "None") ? "Undefined" : (item['DC_FLAG'] === "0") ? "NON-DC" : "DC"
        });
      }
      if (!(item['STORE_TYPE'] in lookupStoreType)) {
        lookupStoreType[item['STORE_TYPE']] = 1;
        listStoreType.push({ id: 'store_type', value: item['STORE_TYPE'], label: item['STORE_TYPE'] });
      }
    }
    listAccount.sort((a, b) => {
      const valueA = (a.label || '').toLowerCase();
      const valueB = (b.label || '').toLowerCase();

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });
    listDCflag.sort((a, b) => {
      const valueA = (a.label || '').toLowerCase();
      const valueB = (b.label || '').toLowerCase();

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });
    listStoreType.sort((a, b) => {
      const valueA = (a.label || '').toLowerCase();
      const valueB = (b.label || '').toLowerCase();

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

    setAccountFilter(listAccount)
    setDcFilter(listDCflag)
    setStoreTypeFilter(listStoreType)

  }

  // login() {
  //  props.auth.login();
  // }

  // isSuperUser() {
  //   return assignAccess(client_id);
  // }

  // logout() {
  //  props.auth.logout();
  // }

  // isSSOAuthenticated(id_tok_nonce) {
  //  props.auth.isSSOAuthenticated(id_tok_nonce);
  // }

  const checkForSkyUser = () => {
    return profile_client === "SKY";
  }

  const handleClose = () => {
    setModalState({
      'show': false
    });
  }

  //For pop up window showing google map
  const handleShow = (row) => {
    getStoreMetrics({ tpid: row['TRADE_PARTNER_ID'], store_num: row['STORE_NUMBER'] }).then(values => {
      const finalRow = {
        ...row,
        ...values[0]
      }

      setModalState({
        'show': true,
        'modalRow': finalRow
      });
    });
  }

  //logic for determining what is editable or not
  const renderEditable = (cellInfo) => {
    console.log(cellInfo);
    let originalValue, currentValue, column;
    let editList = ["STORE_NAME", "STATE", "CITY", "UDEF9", "ADDRESS", "ZIP", "STORE_TYPE", "DC_FLAG"];
    let checkList = ["TRADE_PARTNER_ID", "STORE_NUMBER", "RECORD_ID"];
    column = cellInfo.target.id.split("|")[0]; // here we are getting the column
    let original_tpid = cellInfo.target.id.split("|")[1];
    let original_store_number = cellInfo.target.id.split("|")[2];

    let originalStore = oData.filter((item) => {
      return (String(item['TRADE_PARTNER_ID']) === String(original_tpid) && String(item['STORE_NUMBER']) === String(original_store_number));
    });
    originalValue = originalStore[0][column]; // Assuming there's only one matching item
    currentValue = cellInfo.target.value;

    // Making a copy of modifiedRows
    let modifiedRows = [...uData];
    let tempData = cData.length !== 0 ? cData : oData

    // If column is in the editList
    if (editList.indexOf(column) >= 0) {
      // Update cData and modifiedRows
      // const updatedCData = cData.map((item) => {
      const updatedCData = tempData.map((item) => {
        if (String(item['TRADE_PARTNER_ID']) === String(original_tpid) && String(item['STORE_NUMBER']) === String(original_store_number)) {
          // Logic to update cData
          item = { ...item, [column]: currentValue };

          // Logic for modified modifiedRows/uData
          if (String(originalValue) !== String(currentValue)) {
            const existingRowIdx = modifiedRows.findIndex((row) => {
              for (let key in row) {
                if (checkList.indexOf(key) >= 0 && String(item[key]) !== String(row[key])) {
                  return false;
                }
              }
              return true;
            });

            if (existingRowIdx >= 0) {
              modifiedRows[existingRowIdx][column] = currentValue;
            } else {
              modifiedRows.push({ ...item });
            }
          }
          else {
            modifiedRows.forEach((row, index) => {
              if (String(row['TRADE_PARTNER_ID']) === String(original_tpid) && String(row['STORE_NUMBER']) === String(original_store_number)) {
                if (JSON.stringify(originalStore[0]) === JSON.stringify(item)) {
                  modifiedRows.splice(index, 1);
                }
                else {
                  modifiedRows[index][column] = currentValue;
                }
              }
            });
          }
        }
        return item;
      });

      // Update state variables
      setCData(updatedCData);
      setUData(modifiedRows);
      // console.log(`${username} edited ${column} value from ${originalValue} to ${currentValue} in row: Account = ${account.value} table & Store Number = ${original_store_number}` )
    }
  }

  var data, accountValue, accountFilterList = [], storeTypeFilterList = [];
  const modifiedRows = uData;

  accountValue = acc_select;

  if (!accountValue) {
    accountValue = "";
  }

  // if (cData !== null && accountValue !== "") {
  //   data = cData;
  // } else {
  //   data = [];
  // }
  if (cData.length !== 0) {
    data = cData;
  }
  else {
    data = []
  }

  let dcFilter = [];

  if (tableFilters) {
    dcFilter = tableFilters.dc.sort().map(x => {
      return { 'label': (x === null ? "Undefined" : (x === 0) ? "NON-DC" : "DC"), 'value': x }
    });
    dcFilter.unshift({ value: 'all', label: 'All' });

    accountFilterList = tableFilters.account.filter(x => x !== null).sort().map(x => {
      return { 'label': x, 'value': x }
    });

    const uniqueStrings = new Set();

    tableFilters.store_type.forEach((str) => {
      if (str) {
        const cleanedString = str.replace(/\s/g, "");

        uniqueStrings.add(cleanedString);
      }
    });

    const storeTypeArray = Array.from(uniqueStrings);
    storeTypeFilterList = storeTypeArray.sort().map(x => {
      return { 'label': x, 'value': x }
    });
    storeTypeFilterList.unshift({ value: 'all', label: 'All' });
  }

  if (newStoreData.length !== 0) {
    const newStoreDataaDcValues = newStoreData.map(item => item.DC_FLAG);
    const newStoreDataAccountValues = newStoreData.map(item => item.Account);
    const newStoreDataStoreTypeValues = newStoreData.map(item => item.STORE_TYPE);

    dcFilter = [...new Set(newStoreDataaDcValues)]
      .sort()
      .map(x => ({
        'label': (x === "None" ? "Undefined" : (x === 0) ? "NON-DC" : "DC"),
        'value': x
      }));
    dcFilter.unshift({ value: 'all', label: 'All' });

    accountFilterList = [...new Set(newStoreDataAccountValues)]
      .sort()
      .map(x => ({
        'label': x,
        'value': x
      }));

    storeTypeFilterList = [...new Set(newStoreDataStoreTypeValues)]
      .map(x => ({
        'label': x,
        'value': x
      }));
    storeTypeFilterList.unshift({ value: 'all', label: 'All' });
  }

  return (
    <div className="container-fluid">
      {
        <Nav
          is_auth={isAuthenticated}
          user={username}
          client={profile_client}
        // login={this.login.bind(this)}
        // logout={this.logout.bind(this)}
        ></Nav>
      }
      {isAuthenticated && (
        <form className="card form-signin" onSubmit={handleSubmit}>
          {assignAccess(client_id)}
          <div>
            <img
              alt="skypadlogo"
              width="250"
              src="https://red.skypad.biz/qlikview/images/SKYPAD%20Logo.png"
            />
          </div>
          <h3 className="form-signin-heading">
            <b>Store Master</b>
          </h3>

          <div hidden={!checkForSkyUser()}></div>
          <br></br>

          <div className="basic-single col-lg-3 p-3">
            <Select
              value={account}
              className="basic-single col"
              id="acc_select"
              placeholder="Select Account"
              title="Account"
              options={accountFilterList}
              onChange={e => setAccount(e)}
            />
          </div>
          <div className="basic-single col-lg-3 p-3">
            <Select
              value={dcFlag}
              className="basic-single col"
              id="dc_flag"
              placeholder="Select DC Flag"
              title="DC Flag"
              options={dcFilter.sort()}
              onChange={e => setDcFlag(e)}
            />
          </div>
          <div className="basic-single col-lg-3 p-3">
            <Select
              value={storeType}
              className="basic-single col"
              id="store_type"
              placeholder="Select Store Type"
              title="Store Type"
              options={storeTypeFilterList}
              onChange={e => setStoreType(e)}
            />
          </div>
          <div className="basic-single col-lg-1 p-3">
            <input
              type="button"
              className={"btn btn-primary"}
              value={newStore_allStore}
              onClick={() => {
                if (newStore_allStore === 'New Stores') {
                  generateNewStoreData();
                } else {
                  generateStoreData();
                }
              }}
            />
          </div>
          <div id="loadMsg" style={{ display: "none" }}>Fetching new data...</div>

          <div className={"basic-single col-lg-1 p-3 " + loaderAnimation}>
            <FadeLoader
              sizeUnit={"px"}
              size={10}
              color={"#123abc"}
              loading={true}
            />
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <Table
            // data={storeList}
            data={data}
            modified={modifiedRows}
            editable={renderEditable}
            handleShow={handleShow}
            handleClose={handleClose}
            show={show}
            modalRow={modalRow}
            user={user.nickname}
            updateAccess={updateAccess}
            account={account}
            modalState={modalState}
          ></Table>

          <input type="submit" className="btn-submit" />
        </form>
      )}
    </div>
  );
}

export default StoreMaster;