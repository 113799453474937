import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
 import './style.css';
const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: "black",
      background: "#ffff00",
      padding: "7px 7px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      transform: "translate(-50%, -50%)",
      opacity: 0.6,
      width: 30,
      height: 30,
    }}
  >
    {text}
  </div>
);

const handleApiLoaded = (map, maps) => {
  //map.setMapTypeId('hybrid')
  map.setOptions({
    gestureHandling: 'greedy',
    //fullscreenControl: false
  }) //set to greedy to set autozoom
};

function SkyModal(props) {
  var show = false,
    handleShow,
    handleClose,
    row;

  show = props.show;
  handleShow = props.handleShow;
  handleClose = props.handleClose;
  row = props.row;

  if(props.row === undefined && props.clickModal===true)
    {
      show=true;
    }
  if (!row) {
    row = {};
    row["Account"] = "";
    row["latitude"] = "";
    row["longitude"] = "";
    row["STORE_NAME"] = "";
    row["STORE_NUMBER"] = "";
  } else {
    row["latitude"] = isNaN(parseFloat(row["latitude"]))
      ? undefined
      : parseFloat(row["latitude"]);
    row["longitude"] = isNaN(parseFloat(row["longitude"]))
      ? undefined
      : parseFloat(row["longitude"]);
  }

  return (
    <Modal
      bsSize="large"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div style={{ height: "50vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCndoMZZi_FRwy9Xj9oxzTyyGzMqP2pi1Q",
              language: "en",
            }}
            defaultCenter={{ lat: row["latitude"], lng: row["longitude"] }}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            <AnyReactComponent
              lat={row["latitude"]}
              lng={row["longitude"]}
              text={row["STORE_NUMBER"]}
            />
          </GoogleMapReact>
        </div>
        <div
          id="cue-card-info"
          style={{
            color: "grey",
            fontFamily: "Arial",
            fontStyle: "normal",
          }}
        >
          <div
            style={{
              color: "grey",
              fontFamily: "Arial",
              fontSize: 20,
              fontStyle: "normal",
              marginBottom: 0,
              marginTop: 15,
              textAlign: "center",
            }}
          >
            {row["TRADE_PARTNER_ID"]}
          </div>
          <table
            class="table table-bordered"
            style={{
              color: "grey",
              fontFamily: "Arial",
              fontStyle: "normal",
              marginTop: 5,
            }}
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  Store #
                </th>
                <th
                  scope="col"
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  Sls U
                </th>
                <th
                  scope="col"
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  Sls $
                </th>
                <th
                  scope="col"
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  OH U
                </th>
                <th
                  scope="col"
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  OH $
                </th>
                <th
                  scope="col"
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  Shp U
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  {row["STORE_NUMBER"]}
                </td>
                <td
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  {parseInt(row["TOTAL_SLS_U"])
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </td>
                <td
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  {"$" +
                    parseFloat(row["TOTAL_SLS_DLRS"])
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </td>
                <td
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  {parseInt(row["TOTAL_OH_U"])
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </td>
                <td
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  {"$" +
                    parseFloat(row["TOTAL_OH_DLRS"])
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </td>
                <td
                  style={{
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  {parseInt(row["TOTAL_SHP_U"])
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </td>
              </tr>
            </tbody>
          </table>
          {"Number of clients with activity: " + row["NO_OF_CLIENTS"]}
          <br></br>
          {"First recorded activity date: " + row["MIN_ENDING_DATE"]}
          <br></br>
          {"Last recorded activity date: " + row["MAX_ENDING_DATE"]}
          <br></br>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SkyModal;
