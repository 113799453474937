import { createMemoryHistory } from 'history';
import React from 'react'
import StoreMaster from '../../js/store_master';
import { BrowserRouter, useRoutes } from 'react-router-dom';
const history = createMemoryHistory();

history.listen((location) => {
  console.log(location)
});

const RouteApp = () => {
  const routes = useRoutes([
        { path: '/', element: <StoreMaster /> }
  ]);
  return routes;
}

const Route = () => {
  // let historyHook = useHistory();
  // const { user } = useAuth0();
  // const {updateHistory} = useContext(ApiContext)

  // useEffect(() => {
  //     if (!historyHook && user) {
  //         updateHistory(history)
  //         let t = user["http://skypad_user/groups"].filter(x => x === 'QlikSense Users').length;
  //         if(t < 1){
  //             history.push({ pathname: '/error', state: "You don't have access to this application" });
  //         } else {
  //             const selApp = JSON.parse(localStorage.getItem('selApp'));
  //             if(selApp){
  //                 if(history.location.pathname === '/'){
  //                     history.push('/dashboard');
  //                 }
  //             } else {
  //                 history.push('/accesspoint');
  //             }
  //         }
  //     }
  // }, [historyHook])

  return (
    <BrowserRouter>
      <RouteApp />
    </BrowserRouter>
  );
}

export default Route;
