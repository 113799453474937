import { BASE_URL_AUTH } from "../../apis/configs/axiosConfig";


export const AUTH_CONFIG = {
  // domain: 'skypad.auth0.com',
  // clientId: 'hn3r1Pc7ObGZtzVpeh9hCdsdhGoH335j',
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  callbackUrl: BASE_URL_AUTH,
  baseUrl: BASE_URL_AUTH
}
