import React, { useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Route from '../src/layout/Route';

function App() {
  // timeOut();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, isLoading])

  return (
    isAuthenticated && !isLoading && <Route />
  );
}
export default App;