import App from './App';
import ReactDOM from 'react-dom';
import React from 'react'
import './css/index.css';
// import 'bootstrap/dist/css/bootstrap.css';
import { AUTH_CONFIG } from '../src/js/auth/auth0-variables';
import { Auth0Provider } from '../src/contexts/provider';
import { ApiProvider } from './contexts/ApiContext';

ReactDOM.render(
  // <OverlayProvider>
  <Auth0Provider
    domain={AUTH_CONFIG.domain}
    clientId={AUTH_CONFIG.clientId}
    // redirectUri={AUTH_CONFIG.callbackUrl}
    state={AUTH_CONFIG.clientName}
    authorizationParams={{
      redirect_uri: AUTH_CONFIG.callbackUrl,
      audience : 'storemaster'
    }}
    // audience='storemaster'
    scope="openid profile email"
    responseType="token id_token"
  >
    <ApiProvider>
    <App />
    </ApiProvider>
  </Auth0Provider>,
  // </OverlayProvider>,
  document.getElementById('root')
);