import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../css/app.css';

function Nav(props) {
  const { logout } = useAuth0();
  const isAuthenticated = props.is_auth;
  const user = props.user;

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className="d-flex flex-row-reverse">
      <div className="p-2">
        <button bsStyle="primary" className={isAuthenticated ? "btn-margin-logout" : "btn-margin-login"} onClick={isAuthenticated ? handleLogout : props.login}>
          {isAuthenticated ? "Log Out" : "Log In"}
        </button>
      </div>
      <div className="p-2">
        {isAuthenticated && <h5>Welcome {user}!</h5>}
      </div>
    </div>
  );
}

export default Nav;

