import React, {
    useMemo, useState, useContext
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInt from '../apis/configs/axiosConfig';
import { AUTH_CONFIG } from '../js/auth/auth0-variables';

export const ApiContext = React.createContext();

export const ApiProvider = ({ children }) => {

    const { getAccessTokenSilently } = useAuth0();

    const getAPI =
        async (config) => {
            let access_token_hook = await getAccessTokenSilently();

            config.headers = {
                authorization: `Bearer ${access_token_hook}`
            }
            return new Promise((resolve, reject) => {
                axiosInt.request(config)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(ex => {
                    })
            })
        };

    // const getAccessToken = () => {
    //     var options = {
    //         method: 'POST',
    //         url: 'https://{yourDomain}/oauth/token',
    //         headers: {'content-type': 'application/x-www-form-urlencoded'},
    //         data: new URLSearchParams({
    //           grant_type: 'client_credentials',
    //           client_id: 'YOUR_CLIENT_ID',
    //           client_secret: 'YOUR_CLIENT_SECRET',
    //           audience: 'YOUR_API_IDENTIFIER'
    //         })
    //       };
          
    //       axios.request(options).then(function (response) {
    //         console.log(response.data);
    //       }).catch(function (error) {
    //         console.error(error);
    //       });
    // }

    const getTableFilters = async () => {
        let config = {
            url: '/accounts',
            method: 'get'
        }
        let response = await getAPI(config);
        return response
    }

    const getStoreData = async (data) => {
        let config = {
            url: '/stores',
            method: 'post',
            data,
        }

        let response = await getAPI(config);
        return response
    }

    const getStoreMetrics = async (data) => {
        let config = {
            url: '/store_metrics',
            method: 'get',
            params: data,
        }

        let response = await getAPI(config);
        return response

    }

    const makeStoreData = async () => {
        let config = {
            url: '/all_stores',
            method: 'get',
        }

        let response = await getAPI(config);
        return response

    }

    const makeNewStoreData = async () => {
        let config = {
            url: '/new_stores',
            method: 'get',
        }

        let response = await getAPI(config);
        return response

    }

    const submitData = async (data) => {
        let config = {
            url: '/load_store',
            method: 'post',
            params: data,
        }

        let response = await getAPI(config);
        return response

    }

    const contextValue = useMemo(() => {
        return {
            getTableFilters,
            getStoreData,
            getStoreMetrics,
            makeStoreData,
            makeNewStoreData,
            submitData
        };
    }, [
        getTableFilters,
        getStoreData,
        getStoreMetrics,
        makeStoreData,
        makeNewStoreData,
        submitData
    ]);

    return (
        <ApiContext.Provider value={contextValue}>
            {children}
        </ApiContext.Provider>
    );
};