import axios from 'axios';

export let BASE_URL_AUTH = window.location.origin;
export let BASE_URL = window.location.origin;

if (process.env.REACT_APP_BUILD_ENV === 'local') {
  // BASE_URL = `http://localhost:3041`;
  BASE_URL = `https://storemaster.skypad.biz/`;
}

// if (window.location.hostname === "localhost") {
//   BASE_URL = `http://localhost:3041`;
// }

const axiosInt = axios.create({
  baseURL: BASE_URL + '/'
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
  Promise.reject(
    (error || error.response || error.response.data)
  )
);

export default axiosInt;