import React, { useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
// import ReactTooltip from 'react-tooltip';
import SkyModal from './modal.js';

//This table component has all the properties, styling, filtering involved
function Table(props) {

  const [selected, setSelected] = useState(-1);

  var data, modifiedRows, loading, user, modalRow, handleShow, handleClose, show, editable, updateAccess, accountSelected;
  var allowedToEdit = ['d.bakrania', 'k.zayarniy', 'j.zhang', 'm.lynch', 'h.saljanin', 'h.ishida', 'k.rodriguez', 'k.bondy', 'd.reid', 'h.montgomery', 'd.kim'];
  data = props.data;
  modifiedRows = props.modified;
  user = props.user;
  handleShow = props.handleShow;
  handleClose = props.handleClose;
  show = props.modalState.show;
  modalRow = props.modalState.modalRow;
  updateAccess = props.updateAccess;
  accountSelected = props.account;

  // console.log(ReactTable)

  if (data.length > 0) {
    loading = false;
  } else {
    loading = true;
  }

  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);


  return (

    <div>
      <SkyModal selected={selected} handleShow={handleShow} handleClose={handleClose} show={show} row={modalRow} />
      {
          loading && accountSelected === null ?
          <div style={{ textAlign: "center", padding: "35px" }}>
            <h2>Select an Account to display data on table!</h2></div>
          : loading && data.length === 0 ?
            <h3>No Data!</h3>
            :

            <ReactTable
              data={data}
              loading={loading}
              defaultPageSize={25}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}

              getTrProps={(state, rowInfo) => {
                var ri_, containsRow;
                var noCheckList = ['STATE', 'CITY', 'UDEF9', 'STORE_TYPE'];
                ri_ = rowInfo !== undefined ? rowInfo.original : undefined;

                if (ri_) {
                  containsRow = modifiedRows.some((elem, index) => {
                    var res = true;

                    for (var key in elem) {
                      if (noCheckList.indexOf(key) >= 0) continue;
                      if (JSON.stringify(elem[key]) !== JSON.stringify(ri_[key])) {
                        res = false;
                        break;
                      }
                    }
                    return res;
                  });

                  //containsFlag = (String(ri_['md_flag']) === '1');
                }



                return {
                  onClick: (e) => {
                    setSelected(rowInfo.index)
                  },

                  style: {
                    background: containsRow ? '#c2ffaf' : 'white'
                  }
                }
              }
              }

              columns={[
                {
                  columns: [
                    {
                      Header: "Account",
                      accessor: "Account",
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                    },
                    {
                      Header: "Store Number",
                      accessor: "STORE_NUMBER",
                      filterMethod: (filter, row) =>
                        (row[filter.id].toLowerCase() === filter.value.toLowerCase())
                    },
                    {
                      Header: "Store Name",
                      accessor: "STORE_NAME",
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) =>
                      (
                        <input
                          readOnly={!(updateAccess)}
                          className='input-sm container-fluid'
                          id={'STORE_NAME|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={props.editable}
                          value={row.original['STORE_NAME']} />
                      ),
                    },
                    {
                      Header: "City",
                      accessor: "CITY",
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <input
                          readOnly={!(updateAccess && (row.original.latitude === 'None' && row.original.longitude === 'None')) && !(allowedToEdit.includes(user.toLowerCase()))}
                          className='input-sm container-fluid'
                          id={'CITY|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={props.editable}
                          value={row.original['CITY']} />
                      ),
                    },
                    {
                      Header: "State",
                      accessor: "STATE",
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <input
                          readOnly={!(updateAccess && (row.original.latitude === 'None' && row.original.longitude === 'None')) && !(allowedToEdit.includes(user.toLowerCase()))}
                          className='input-sm container-fluid'
                          id={'STATE|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={props.editable}
                          value={row.original['STATE']} />
                      ),
                    },
                    {
                      Header: "Country",
                      accessor: "UDEF9",
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <input
                          readOnly={!(updateAccess && (row.original.latitude === 'None' && row.original.longitude === 'None')) && !(allowedToEdit.includes(user.toLowerCase()))}
                          className='input-sm container-fluid'
                          id={'UDEF9|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={props.editable}
                          value={row.original['UDEF9']} />
                      ),
                    },
                    {
                      Header: "Address",
                      accessor: "ADDRESS",
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <input
                          readOnly={!(updateAccess && (row.original.latitude === 'None' && row.original.longitude === 'None')) && !(allowedToEdit.includes(user.toLowerCase()))}
                          className='input-sm container-fluid'
                          id={'ADDRESS|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={props.editable}
                          value={row.original['ADDRESS']} />
                      ),
                    },
                    {
                      Header: "Zip",
                      accessor: "ZIP",
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <input
                          readOnly={!(updateAccess && (row.original.latitude === 'None' && row.original.longitude === 'None')) && !(allowedToEdit.includes(user.toLowerCase()))}
                          className='input-sm container-fluid'
                          id={'ZIP|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={props.editable}
                          value={row.original['ZIP']} />
                      ),
                    },
                    // {
                    //   Header: "Region",
                    //   accessor: "REGION",
                    //   filterMethod: (filter, row) =>
                    //     row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                    //   //Cell: props.editable
                    // },
                    {
                      Header: "Store Type",
                      accessor: "STORE_TYPE",
                      filterMethod: (filter, row) =>
                        row[filter.id].includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <select
                          id={'STORE_TYPE|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={updateAccess ? props.editable : null}
                          style={{ width: "100%" }}
                          value={row.original.STORE_TYPE.trim()}
                        >
                          <option value={row.original.STORE_TYPE.trim()} disabled>{row.original.STORE_TYPE.trim()}</option>
                          <option value="Undefined">Undefined</option>
                          <option value="Full Line">Full Line</option>
                          <option value="Outlet">Outlet</option>
                          <option value="Direct">Direct</option>
                          <option value="Military">Military</option>
                        </select>
                      ),
                      filterMethod: (filter, row) => {
                        if (filter.value === '') {
                          return '';
                        }
                        if (filter.value === 'Undefined') {
                          return row[filter.id] === 'Undefined';
                        }
                        if (filter.value === 'Full Line') {
                          return row[filter.id] === 'Full Line';
                        }
                        if (filter.value === 'Outlet') {
                          return row[filter.id] === 'Outlet';
                        }
                        if (filter.value === 'Direct') {
                          return row[filter.id] === 'Direct';
                        }
                        if (filter.value === 'Military') {
                          return row[filter.id] === 'Military';
                        }
                      },
                      Filter: ({ filter, onChange }) =>
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : ''}
                        >
                          <option value="Undefined">Undefined Only</option>
                          <option value="">Show All</option>
                          <option value="Full Line">Full Line</option>
                          <option value="Outlet">Outlet</option>
                          <option value="Direct">Direct</option>
                          <option value="Military">Military</option>
                        </select>
                    },
                    {
                      Header: "DC Flag",
                      accessor: "DC_FLAG",
                      maxWidth: 150,
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <select
                          id={'DC_FLAG|' + row.original['TRADE_PARTNER_ID'] + '|' + row.original['STORE_NUMBER']}
                          onChange={updateAccess ? props.editable : null}
                          style={{ width: "100%" }}
                          value={String(row.original.DC_FLAG === "None" ? "Undefined" : row.original.DC_FLAG)}
                        >
                          <option value="Undefined" disabled>--</option>
                          <option value="0">NON-DC</option>
                          <option value="1">DC</option>
                        </select>
                      )
                      //Cell: props.editable
                    },
                    {
                      Header: " ",
                      accessor: " ",
                      maxWidth: 75,
                      filterMethod: (filter, row) =>
                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                      Cell: (row) => (
                        <a href="#"
                          data-toggle="tooltip"
                          onClick={() => { handleShow(row.original) }}
                          title={'Lat: ' + row.original.latitude + ',\nLong: ' + row.original.longitude + ', \nRegion: ' + row.original.REGION + ',\nCurrency: ' + row.original.UDEF6}
                        ><span className='glyphicon glyphicon-info-sign'></span></a>
                        //<button className="btn" onClick={props.handleShow(row.original)}>i</button>
                      )
                    }
                  ]
                }
              ]}

              className="-striped -highlight"
            />

      }
      <br />
    </div>
  );

}

export default Table;
